<template>
    <div class="collect_box">
        <div class="main_header">
            <div class="main_header_text">历史记录</div>
            <div class="main_header_icon" @click="closemin"></div>
        </div>
        <div class="h_header">
            <div class="selectKeys">
                <div class="selectKey" :class="key == 1 ? 'selectKey_active' : ' '" @click="selectKey(1)">全部</div>
                <div class="selectKey" :class="key == 2 ? 'selectKey_active' : ' '" @click="selectKey(2)">文件</div>
                <div class="selectKey" :class="key == 3 ? 'selectKey_active' : ' '" @click="selectKey(3)">图片/语音</div>
            </div>
            <div class="search">
                <div style="height:24px; width: 90%;"><a-input-search placeholder="搜索" v-model="searchValue" @search="onSearch"></a-input-search></div>
                <div class="date" @click="selectDate"></div>
            </div>
            <div class="list" id="list_message" >
                <div class="loadMore" :class="loading? '': 'loadMoreh'" @click="loadMore">
                {{loading? '加载中...':'点击加载更多'}}
                </div>
                <!-- <div class="date-line">10-13</div> -->
                <div class="item-from" v-for="(item,index) in messageList" :key="item.id">
                    <div class="item-header">
                        <div class="item-header-text">{{getName(item)}}</div>
                        <div class="item-header-time">{{dateFormat('YYYY-MM-DD HH:mm',item.time)}}</div>
                    </div>
                    <div class="item-body">
                        <!-- 文本消息 -->
                        <div class="body-text" v-if="item.type == 'txt' && item.msg.indexOf('[') < 0" >{{item.msg}}</div>
                        <!-- 表情消息 -->
                        <div class="body-text" v-if="item.type == 'txt' && item.msg.indexOf('[') >= 0"><img :src="getEmimg(item)" /></div>
                        <!-- 图片消息 -->
                        <div class="body-text" v-if="item.type == 'img'"><img class="image-box" :src="item.url" @click="preImg(item.url)"></div>
                        <!-- 文件消息 -->
                        <div class="body-text file-box" v-if="item.type == 'file'" >
                            <div ><img class="image-box-file" :src="getimage(item.filename)"></div>
                            <div style="margin-left: 16px;">
                                <div style="margin-top: 4px;">{{ item.filename }}</div>
                                <div style="color:#999999; margin-top: 10px;">{{ fileSizeFormat(item.file_length) }} <a class="file_download" :href="item.url" download>点击下载</a></div>
                            </div>
                        </div>
                        <!-- 语音消息 -->
                        <div class="body-text text-body"  v-if="item.type == 'audio'"  @click="startplayAudio(item,index)" style="display: flex;">
                            <div style="width: 130px;cursor: pointer; font-size: 12px;">{{audioPlayStatus.playIndex === index ? "播放中" : '单击播放'}}</div>
                            <img class="image-box-audio" src="@/assets/audio.png">
                            <div class="audio-text" v-if="item.type == 'audio'">{{item.length}}′′</div>
                        </div>
                        <!-- 视频消息 -->
                        <div class="body-text text-body" v-if="item.type == 'video'"  @click="startplayAudio(item,index)" style="display: flex;">
                            <video :src="item.url" style="width: 200px; height: 250px;" controls="controls"></video>
                        </div>
                        <!-- 自定义消息 -->
                        <div class="body-text text-body" v-if="item.type == 'custom'">
                            <div>{{item.customExts.status}}</div>
                            <div class="custom-body">
                                <div class="custom-img">
                                <img :src="item.customExts.imgUrl"/>
                                </div>
                                <div class="custom-text">{{getStringExt(item.customExts.title)}}</div>
                            </div>
                            <div class="custom-action">
                                <a-button type="default" class="custom-ok-cleal" @click="preInfos(item)">{{getButtonTextTwo(item.customExts)}}</a-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a-modal title="" class="imgdialog" style="bottom: 20px" v-model="imgVisible" :closable="false"  :footer="null" width="960px" height="640px">
                <div class="imgheader">
                <div class="imgtitle">图片预览</div>
                <div class="imgtitleaction">
                    <div class="close" style="margin-top: 5px; margin-right: 0px;" @click="minimg()"></div>
                </div>
                </div>
                <div class="imgbox">
                <img :src="preimgurl" draggable="true" :style="{transform:'scale('+multiples+')'}"  @dragstart="dragstart($event)" @dragend="dragend($event)" >
                </div>
                <div class="imgaction">
                <a href="#" style="margin-left: 10px;" @click="sximg"><img src="@/assets/suoxiao.png"/></a>
                <a href="#" style="margin-left: 10px;" @click="pfimg"><img src="@/assets/fangda.png"/></a>
                <a :href="preimgurl" style="margin-left: 10px;" download><img src="@/assets/xiazai.png"/></a>
                </div>
            </a-modal>
            <a-modal title="" class="imgdialog" style="bottom: 20px" v-model="topvl" :closable="false"  :footer="null" width="260px" height="140px">
                <div class="imgtitle">请前往APP端进行操作</div>
              
            </a-modal>
        </div>
        
    </div>
</template>
<script>
import { EaseChatClient, EaseChatSDK } from '@/IM/initwebsdk'
import storage from 'store2'
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import emMap  from '@/utils/em'
import dateFormat from '@/utils/dateFormater'
import { ipcApiRoute ,requestHttpGet} from '@/api/main'
import fileSizeFormat from '@/utils/fileSizeFormat'
import BenzAMRRecorder from 'benz-amr-recorder'
export default {
    props: {
        uid: {
            type: String,
            default: ''
        },
        chatType: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            key:1,
            userid:"",
            type:"",
            searchValue:"",
            messageList:[],
            loadList:[],
            msgTypes:[],
            emMap:emMap,
            dateFormat:dateFormat,
            preimgurl:"",
            imgVisible:false,
            multiples:1,
            fileSizeFormat: fileSizeFormat,
            audioPlayStatus:{
                playIndex:-1,
                isPlaying:false
            },
            topvl:false,
            loading:false,
            page:""
            
        };
    },
    watch: {
        uid: function () {
            this.userid = this.uid
            this.initdata()
        },
        chatType: function () {
            this.type = this.chatType
        }
    },
    mounted() {
        this.userid = this.uid
        this.type = this.chatType
        this.initdata()
    },
    beforeDestroy() {

    },
    methods: {
        preInfos(e){
            this.topvl = true
        },
        //截取字符串长度
        getStringExt(e){
        if(e.length > 24){
            return e.substring(0,24)+'...'
        }
        return e
        },
        getButtonTextTwo(e){
            let res = ""
            switch (e.status) {
                case '请您仔细查阅需求并进行确认':
                res = "确认需求"
                break;
                case '请您确定设计方案':
                res = "去确认"
                break;
                case '请确定申请修改':
                res = "确认申请"
                break;
                case '请您对我们的方案做出评价':
                res = "立即评价"
                break;
                case '点击查看详情':
                res = "查看详情"
                break;
                default:
                res = "查看详情"
            }
            return res
        },
        getimage(item){
            let res = require("@/assets/pngfile.png")
            switch (item.split(".")[1]) {
                case 'png':
                    res = require("@/assets/pngfile.png")
                break;
                case 'doc':
                    res =  require("@/assets/wfile.png")
                break;
                case 'xlsx':
                    res =  require("@/assets/xfile.png")
                break;
                case 'dwg':
                    res =  require("@/assets/cadfile.png")
                break;
                case 'zip':
                res =  require("@/assets/zfile.png")
                break;
                case 'pptx':
                res =  require("@/assets/pfile.png")
                break;
                default:
                break;
            }
            return res
        },
        startplayAudio(item,index){
            const armRec = new BenzAMRRecorder()
            const src = item.url
            this.audioPlayStatus.playIndex = index
            console.log('>>>>>开始播放音频', item.url)
            //初始化音频源并调用播放
            armRec.initWithUrl(src).then(() => {
                if (!this.audioPlayStatus.isPlaying) {
                    armRec.play()
                }
            })
            //播放开始监听
            armRec.onPlay(() => {
                this.audioPlayStatus.isPlaying = true
                this.audioPlayStatus.playIndex = index
            })
            //播放结束监听
            armRec.onStop(() => {
                this.audioPlayStatus.isPlaying = false
                this.audioPlayStatus.playIndex = -1
            })
        },
        preImg(url){
            if (this.$ipc) {
                storage.set("preImages",url)
                this.$ipc.invoke(ipcApiRoute.newWinSize, {name:"preimg",content:window.location.protocol+"//"+window.location.host+"/#/preimg",title:'图片预览'}).then(result => {
                    console.log(result)
                })
            } else {
                this.preimgurl = url
                this.imgVisible = true
            }
        },
        sximg(){
            if (this.multiples <= 1) {
                return;
            }       
            this.multiples -= 0.25;
            },
        pfimg(){
            if (this.multiples >= 2) {
                return;
            }       
            this.multiples += 0.25;
        },
        getEmimg(item){
        if(item.msg.indexOf('[') >=0 ){
            if(typeof(this.emMap[item.msg]) == "undefined"){
            return item.msg
            }else{
            return require('../assets/faces/' + this.emMap[item.msg])
            }
        }else{ 
            return item.msg
        }
        },
        closemin(){
            this.$emit('closemin')
        },
        getName(item){
            if(storage.get("ImAcount") == item.from){
                return "我"
            }else{
                return item.from
            }
        },
        onSearch(){
            this.initdata();
        },
        selectDate(){

        },
        selectKey(e){
            this.key = e
            this.msgTypes = []
            if(this.key == 2){
                this.msgTypes.push('file')
            }
            if(this.key == 3){
                this.msgTypes.push('video')
                this.msgTypes.push('img')
            }
            this.initdata()
        },
        loadMore(){
            console.log("点击加载更多")
            this.orderscrollEvent()
        },
        async orderscrollEvent(e){
            var that = this
            that.loading = true
            let searchOptions = {}
            if(this.msgTypes.length > 0){
                searchOptions.msgTypes= this.msgTypes
            }
            //加载更多
            console.log(this.messageList)
            this.page = this.messageList[0].id
            that.loadList = await EaseChatClient.getHistoryMessages({targetId:this.userid,chatType:this.type, pageSize:10,searchDirection:"up",searchOptions:searchOptions,cursor:this.page})
            console.log(that.loadList)
            setTimeout(() => {
                for(let index = that.loadList.messages.length-1; index >=0; index--) {
                    that.messageList.unshift(that.loadList.messages[index])
                }
                that.$forceUpdate();
                that.loading = false
            }, 300);
        },
        async initdata(){
            //获取历史记录
            let searchOptions = {
            }
            if(this.msgTypes.length > 0){
                searchOptions.msgTypes=this.msgTypes
            }
            console.log(searchOptions)
            let res = await EaseChatClient.getHistoryMessages({targetId:this.userid,chatType:this.type, pageSize:10,searchDirection:"up",searchOptions:searchOptions,cursor:-1})
            this.messageList = res.messages
            this.messageList.sort((a, b) => { return a.time - b.time })
            this.$forceUpdate()
            setTimeout(() => {
                let scrollEl = document.getElementById("list_message")
                console.log(scrollEl.scrollHeight)
                scrollEl.scrollTo({ top: scrollEl.scrollHeight + 200, behavior: 'smooth' });
            }, 500);
            console.log(res)
        }
    },

}
</script>
<style lang="less" scoped>
.collect_box {
    padding-top: 0px;
}
.main_header{
    display: flex;
    justify-content: space-between;
}
.main_header_text{
    font-family: PingFangSCMedium-Medium;
    font-size: 14px;
    color: #222222;
}
.main_header_icon{
    width:16px;
    height:16px;
    background-image:url("~@/assets/main-close.png");
    background-size:100%;
    cursor: pointer;
}
.date{
    width:24px;
    height:24px;
    background-image:url("~@/assets/date.png");
    background-size:100%;
    cursor: pointer;
}
.h_header{
    margin-top: 16px;
    padding-left: 10px;
    padding-right: 10px;
}
.selectKeys{
    display:flex;
}
.selectKey{
    margin-right:32px;
    margin-top:5px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    letter-spacing: 0px;
    color: #222222;
    cursor: pointer;
}
.selectKey_active{
    margin-right:32px;
    margin-top:5px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    letter-spacing: 0px;
    color: #ED2616;
    cursor: pointer;
}
.search{
    display:flex;
    justify-content:space-between;
    margin-top:16px;
}
/deep/ .ant-input{
    height:24px;
}
.date-line{
    font-family: PingFangSC-Medium;
    font-size: 10px;
    font-weight: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;

}
.list{
    height:350px;
    margin-top:16px;
    text-align:left;
    overflow-y:auto;
}
.list::-webkit-scrollbar{
  background-color: #fff;
  width: 3px;
}
.list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 2px rgba(0,0,0,0.1);
  background: rgba(0,0,0,0.1);
}
.date-line{
    text-align:center;
}
.item-from{
    margin-top:8px;
}
.item-header{
    display:flex;
}
.item-body{
    margin-top:10px;
}
.item-header-text{
    margin-right:10px;
    font-family: PingFangSC-Regular;
    font-size: 10px;
    font-weight: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #666666;
}
.item-header-time{
    margin-right:10px;
    font-family: PingFangSC-Regular;
    font-size: 10px;
    font-weight: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #666666;
}
.body-text{
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    letter-spacing: 0px;
    color: #444444;
}
.image-box {
  width: 100px;
}

.imgheader{
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  position: relative;
}
/deep/ .ant-modal-body{
  padding: 16px;
}
.imgbox{
  display: flex;
  justify-content: center;
  height: 640px;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.imgbox img{
  max-width: 80%;
  max-height: 640px;
  position: absolute;
  z-index: 10;
  user-select: none;
}
.imgaction{
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  margin: auto;
}
.imgtitleaction{
  position: absolute;
  right: 16px;
  top: 0px;
}
.file-box {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.image-box-file {
  width: 54px;
}
.image-box-audio{
  width: 14px;
  cursor: pointer;
}
.audio-text{
  font-family: PingFangSCRegular-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #999999;
  margin-left: 8px;
}
.text-body {
  max-width: 40%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  background: #EEEEEE;
  margin-top: 6px;
}
.custom-body{
  display: flex;
  justify-content: flex-start; 
  margin-top: 8px;
}
.custom-img{
  width: 60px;
  height: 60px;
}
.custom-img img{
  width: 60px;
  height: 60px;
  border-radius: 5px 0px 0px 5px;
}
.custom-text{
  background-color: #FFF;
  height: 60px;
  width: 180px;
  border-radius: 0px 5px 5px 0px;
  padding: 10px;
}
.custom-action{
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.custom-cel{
  border-radius: 20px;
  height: 29px;
  width: 100px;
  box-sizing: border-box;
}
.custom-ok{
  border-radius: 20px;
  width: 100%;
  height: 29px;
  opacity: 1;
  background: linear-gradient(90deg, #FF6044 0%, #ED2616 100%);
  box-shadow: 0px 8px 8px 0px rgba(237, 38, 22, 0.1);
  border: none;
}
.custom-ok-cleal{
  border-radius: 20px;
  width: 100%;
  height: 29px;
  opacity: 1;
  background: none;
  background-color:none;
}
.loadMore{
  font-size: 12px;
  color: #999999;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
}
.loadMoreh{
  color: blue;
  text-align: center;
}
</style>
