<template>
    <div class="collect_box">
        <div class="seach">
            <a-input-search placeholder="搜索群成员" v-model="searchValue" @search="onSearch"></a-input-search>
        </div>
        <div class="user-list">
            <div class="user-header" v-for="item in userList" :key="item.id">
                <img class="user-header-img" :src="item.avatarurl">
                <div class="user-header-text">{{item.nickname}}</div>
            </div>
            <div class="user-header" v-if="getIsOwner()">
                <img class="user-header-img" src="@/assets/addcoumter.png" @click="openAction('add')">
            </div>
            <div class="user-header" v-if="getIsOwner()">
                <img class="user-header-img" src="@/assets/remocoumter.png" @click="openAction('remo')">
            </div>
        </div>
        <div class="user-line"></div>
        <div class="group-title">群名称</div>
        <div class="group-dec">{{name}}</div>
        <div class="group-title">备注</div>
        <div class="group-dec" style="display: flex;"><a-input v-model="groupNote" class="groupnote"/>  <span class="save-groupnote" style="color: #FE5D42;" @click="saveNote()"> 保存</span></div>
        <div class="group-title">群简介</div>
        <div class="group-dec">{{description}}</div>
        <div class="group-title">群ID</div>
        <div class="group-dec">{{userid}} <span style="color: #FE5D42;" @click="copyhaln(userid)">复制</span></div>
        <div class="user-line"></div>
        
        <div class="action">
            <div class="action-title">置顶</div>
            <div><a-switch  v-model="top" size="small" @change="OnChangeTop" /></div>
        </div>
        <div class="action">
            <div class="action-title">消息免打扰</div>
            <div><a-switch  v-model="miss" size="small" @change="OnChangeMiss" /></div>
        </div>
        <div class="buttons">
            <a-button @click="actionEdit">{{getActionName()}}</a-button>
        </div>
        <a-modal v-model="groupvisible" :title="null" :footer="null" :closable="false" width="410px" :centered="true">
            <div class="group-body">
                <div class="group-left">
                    <div class="seachGroupUser">
                    <input class="seachgroupuser-input" maxlength="20" v-model="seachGroupvalue" placeholder="搜索" />
                    <div class="seach-button-group" @click="seachList"></div>
                    </div>
                    <!-- <div class="ftitle" style="margin-top: 8px;">A</div> -->
                    <div class="group-user-list">
                        <a-checkbox-group v-model="lsuserGroups" @change="changeGroupUsers">
                            <div  v-for="item in hhlist" :key="item.id" style="margin-top: 10px;">
                                <a-checkbox :value="item">
                                    <img class="user-header-img" :src="item.avatarurl">
                                    {{item.nickname ? item.nickname : item.id}}
                                </a-checkbox>
                            </div>
                        </a-checkbox-group>
                    </div>
                </div>
                <div class="group-right">
                    <div style="display: flex;justify-content: space-between;">
                        <div class="ftitle" style="margin-top: 0px;">{{getActionGroupName()}}</div>
                        <img class="groupclose" src="@/assets/main-close.png" @click="ceaclCreateGroup">
                    </div>
                    <div class="useUser-body">
                        <div class="useUser" v-for="(item,index) in useUserList" :key="item.id">
                            <img class="user-header-img" :src="item.avatarurl">
                            <div class="useUser-name">{{item.nickname ? item.nickname : item.id}}</div>
                            <img class="useUser-icon" src="@/assets/user-delete.png" @click="deleteUserForUserList(index)">
                        </div>
                    </div>
                    
                    <div style="display: flex; justify-content: space-between;">
                        <button class="login-button-queren" @click="okAddCreateGroup">确定</button>
                        <button class="login-button-quxiao" @click="ceaclCreateGroup">取消</button>
                    </div>
                </div>
            </div>
            
         
        </a-modal>
    </div>
</template>
<script>
import { EaseChatClient, EaseChatSDK } from '@/IM/initwebsdk'
import {requestHttpPost,requestHttpGet} from '@/api/main'
import storage from 'store2'
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
export default {
    props: {
        uid: {
            type: String,
            default: ''
        },
        chatType: {
            type: String,
            default: ''
        }
    },
    watch: {
        uid: function () {
            this.userid = this.uid
            this.initdata()
        },
        chatType: function () {
            this.type = this.chatType
        }
    },
    data() {
        return {
            userid:0,
            type:"",
            userList:[],
            groupNote:"",
            top:false,
            miss:false,
            searchValue:"",
            name:"",
            description:"",
            owner:"",
            groupvisible:false,
            seachGroupvalue:"",
            hhlist:[],
            hhhlist:[],
            hylist:[],
            activeKey:[],
            lsuserGroups:[],
            actionType:"",
            groupNoteId:''
        };
    },
    mounted() {
        this.userid = this.uid
        this.type = this.chatType
        this.initdata()
    },
    beforeDestroy() {

    },
    methods: {
        copyhaln(context){
            let oInput = document.createElement('input');
            // 将想要复制的值
            oInput.value = context;
            // 页面底部追加输入框
            document.body.appendChild(oInput);
            // 选中输入框
            oInput.select();
            // 执行浏览器复制命令
            document.execCommand('Copy');
            // 弹出复制成功信息
            oInput.remove();
            message.info("复制成功!");
        },
        getActionGroupName(){
            if(this.actionType == "add"){
                if(this.lsuserGroups.length>0){
                    return "已选择"+this.lsuserGroups.length+"位联系人"
                }else{
                    return "选择联系人"
                }
            }else{
                if(this.lsuserGroups.length>0){
                    return "已选择"+this.lsuserGroups.length+"删除成员"
                }else{
                    return "选择删除成员"
                }
            }
        },
        //数组去重
        cutarray(arr) {
            var newArr = arr.filter(function(item,index){
                return arr.indexOf(item) === index;  // 因为indexOf 只能查找到第一个  
            });
            return newArr;
        },
        deepClone(obj) {
            var target = {};
            for (var key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    if (typeof obj[key] === 'object') {
                        target[key] = this.deepClone(obj[key]);
                    } else {
                        target[key] = obj[key];
                    }
                }
            }
            return target;
        },
        //根据数据去重数组
        forArrCutArray(arr1,arr2){
            for (let i = 0; i < arr1.length; i++) {
               for (let j = 0; j < arr2.length; j++) {
                if(arr1[i] == arr2[j].id){
                        arr1.splice(i,1)
                    }
               }
                
            }
            return arr1
        },
        async getUserListForGroup(){
            this.hhlist = []
            this.useUserList = []
            if(this.actionType == "add"){
                let userArray = []
                //好友
                let resf = await EaseChatClient.getContacts()
                //会话
                let resh = await EaseChatClient.getConversationlist({pageNum: 0,pageSize: 20})
                //获取用户消息及构建列表
                resh.data.channel_infos.forEach(item => {
                    //判断会话发起人是谁 如果from是自己 取to 发起人不是自己 取from
                    if(item.lastMessage.chatType != "groupchat"){
                        if(item.lastMessage.from == storage.get('ImAcount')){
                            if(item.lastMessage.to != ""){
                                userArray.push(item.lastMessage.to)
                            }
                        }else{
                            if(item.lastMessage.to != ""){
                                userArray.push(item.lastMessage.from)
                            }
                        }
                    }
                });
                let arrUsers = []
                let Userall = []
                arrUsers = userArray.concat(resf.data);
                Userall = this.cutarray(arrUsers);
                let zkall = this.forArrCutArray(Userall,this.userList)
                
                if(Userall.length>100){
                    let resc = this.splitArray(Userall,100)
                    for(let index = 0; index < resc.length; index++) {
                        const e = resc[index];
                        let userinfo = await EaseChatClient.fetchUserInfoById(e)
                        Object.keys(userinfo.data).forEach( item => {
                            this.hhlist.push({
                                id:item,
                                nickname:userinfo.data[item]['nickname'],
                                avatarurl:userinfo.data[item]['avatarurl']
                            })
                        });
                    }
                }else{
                    let userinfo = await EaseChatClient.fetchUserInfoById(Userall)
                    Object.keys(userinfo.data).forEach( item => {
                        this.hhlist.push({
                            id:item,
                            nickname:userinfo.data[item]['nickname'],
                            avatarurl:userinfo.data[item]['avatarurl']
                        })
                    });
                }

               
            }else{
                this.hhlist = this.userList
                
            }
        },
        changeGroupUsers(){
            this.useUserList = this.lsuserGroups
        },
        splitArray(array, size) {
            const result = [];
            for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
            }
            return result;
        },
        seachList(){
            // this.hhlist.
        },
        deleteUserForUserList(i){
             //去掉数组数据
            this.lsuserGroups.forEach((e,index) => {
                if(e.id == this.useUserList[i]['id']){
                    this.lsuserGroups.splice(index,1)
                }
            });
            this.useUserList = this.lsuserGroups
        },
        useUserList(){

        },
        handleClickUser(){

        },
        ceaclCreateGroup(){
            this.groupvisible = false
        },
        async okAddCreateGroup(){
            //邀请用户入群
            //获取群组参数
            if(this.useUserList.length == 0){
                this.groupvisible = false
                return 
            }
            if(this.actionType == 'add'){
                let joinUsers = []
                this.useUserList.forEach(e => {
                    joinUsers.push(e.id)
                });
                await EaseChatClient.inviteUsersToGroup({groupId: this.userid, users: joinUsers})
            }else{
                //删除群成员
                for (let index = 0; index < this.useUserList.length; index++) {
                    const eitem = this.useUserList[index];
                    await EaseChatClient.removeGroupMember({ username:eitem.id, groupId: this.userid })
                }
            }
            message.success("操作成功！")
            this.groupvisible = false
            this.initdata()
        },
        openAction(e){
            this.actionType = e
            this.getUserListForGroup();
            this.groupvisible = true
        },
        async actionEdit(){
            let that = this
            Modal.confirm({
                title: () => '您确定要解散/离开群组?',
                cancelText:"取消",
                okText:"确定",
                okType: 'danger',
                async onOk () {
                    if(that.owner == storage.get("ImAcount")){
                        //解散
                        await EaseChatClient.destroyGroup({groupId: that.userid+''})
                        message.success("群解散成功！")
                    }else{
                        await EaseChatClient.leaveGroup({groupId: that.userid+''})
                        message.success("群退出成功！")
                        //离开
                    }
                    that.$emit("updateInfos",{r:'qz',id:that.userid,value:1})
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        },
        getIsOwner(){
            if(this.owner == storage.get("ImAcount")){
                return true
            }else{
                return false
            }
        },
        getActionName(){
            if(this.owner == storage.get("ImAcount")){
                return '解散群组'
            }else{
                return '离开群组'
            }
        },
        OnChangeTop(){
          let top = false 
          if(storage.has("chatGroupTop") && storage.get("chatGroupTop").split(",").includes(this.userid)){
            // //取消置顶
            // storage.remove("chatGroupTop")
            top = false
          }else{
            //置顶
            // storage.set("chatGroupTop",this.userid)
            top = true
          }
          this.$emit("updateInfos",{r:'zd',id:this.userid,value:top})
        },
        OnChangeMiss(){
            //消息免打扰
            let top = false 
            if(!this.miss){
                EaseChatClient.setSilentModeForConversation({conversationId: this.userid, type: this.type, options: {paramType: 0, remindType: 'ALL'}})
                top = false
            }else{
                EaseChatClient.setSilentModeForConversation({conversationId: this.userid, type: this.type, options: {paramType: 0, remindType: 'NONE'}})
                top = true
            }
            message.success("设置成功！")
            this.$emit("updateInfos",{r:'mdr',id:this.userid,value:top})
        },
        async saveNote(){
            if(this.groupNoteId==''){
                await requestHttpGet('https://www.binliyoujia.com/index.php?ctl=Goods_Imhx&met=usergroup_add&typ=json', {
                type:2,group_id:this.userid,user_id:storage.get("ImAcount"),comment_name:this.groupNote
                })
            }else{
                await requestHttpGet('https://www.binliyoujia.com/index.php?ctl=Goods_Imhx&met=usergroup_edit&typ=json', {
                id:this.groupNoteId,comment_name:this.groupNote
                })
            }
            storage.set('header-name-bz'+this.userid,this.groupNote)
            storage.set('header-name'+this.userid,this.groupNote)
            this.$emit("updateInfos",{r:'note',id:this.userid,value:this.groupNote})
            // let groupNoteInfo = await requestHttpGet('https://www.binliyoujia.com/index.php?ctl=Goods_Imhx&met=usergroup_edit&typ=json', {
            //     type:2,group_id:this.userid,user_id:storage.get("ImAcount"),comment_name:this.groupNote
            // })
            
            this.initdata()
        },
        async onSearch(){
            //查询
            await this.initdata()
            let arr = []
            this.userList.forEach(e => {
            if(e.nickname){
                if(e.nickname.indexOf(this.searchValue)!= -1){
                    arr.push(e)
                }
            }
            });
            console.log(arr)
            this.userList = arr
        },
        async initdata(){
            console.log(this.userid)
            console.log(this.type)
            this.userList = []
            this.top = false
            this.miss = false
            this.name = ""
            this.description =""
            this.owner = ""
            if(this.type == "groupChat"){
                //获取群组信息
                let data = await EaseChatClient.getGroupInfo({groupId:this.userid})
                let groupInfo = data.data[0]
                console.log(groupInfo)
                this.name = groupInfo.name
                this.description = groupInfo.description
                this.owner = groupInfo.owner
                if(storage.has("chatGroupTop")){
                    if(storage.get("chatGroupTop").split(",").includes(this.userid)){
                        this.top = true
                    }
                }
                //获取免打扰状态
                let res = await EaseChatClient.getSilentModeForConversation({conversationId: this.userid,type:'groupChat'})
                if(res.data.type == "NONE"){
                    this.miss = true
                }
                let userarr = []
                console.log(res)
                
                //获取成员信息
                groupInfo.affiliations.forEach(e => {
                    userarr.push(e.member)
                });

                console.log(res)
                let userinfo = await EaseChatClient.fetchUserInfoById(userarr)
                console.log(userinfo)
                Object.keys(userinfo.data).forEach( item => {
                    console.log(item)
                    this.userList.push({
                        id:item,
                        nickname:userinfo.data[item]['nickname'],
                        avatarurl:userinfo.data[item]['avatarurl']
                    })
                });
                //获取备注群名称
                
                let groupNoteInfo = await requestHttpGet('https://www.binliyoujia.com/index.php?ctl=Goods_Imhx&met=usergroup_detail&typ=json', {
                    type:2,group_id:this.userid,user_id:storage.get("ImAcount")
                })
                if(groupNoteInfo.status == 200){
                    this.groupNote  =  groupNoteInfo.data.comment_name?groupNoteInfo.data.comment_name:''
                    this.groupNoteId = groupNoteInfo.data.id?groupNoteInfo.data.id:''
                }
         
                
            }
            if(this.type == "singleChat"){
                //获取个人信息
                
            }
        }
    },

}
</script>
<style lang="less" scoped>
.collect_box {
    padding:16px;
}
.user-list{
    height:146px;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y:auto;
    margin-top:16px;
}
.user-list::-webkit-scrollbar{
    display: none;
}
.user-header{
    width:40px;
    margin-bottom:10px;
    margin-left:5px;
}
.user-header-img{
    width:32px;
    height:32px;
    border-radius:50%;
    cursor: pointer;
}
.user-header-text{
    font-family: PingFang SC;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #222222;
    width:40px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top:5px;
}
.user-line{
    height: 1px;
    opacity: 1;
    background: #EEEEEE;
    margin-top:16px;
}
.group-title{
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0em;
    text-align:left;
    color: #222222;
    margin-top:16px;
}
.group-dec{
    font-family: PingFang SC;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0em;
    text-align:left;
    color: #444444;
    margin-top:8px;
}
.action{
    display:flex;
    justify-content:space-between;
    margin-top:16px;
}
.action-title{
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0em;
    text-align:left;
    color: #222222;
    margin-top:2px;
}
.ant-btn{
    border-radius:32px;
    height:32px;
    width:180px;
    margin-top:80px;
    background-color:#EEEEEE;
}

.seachGroupUser{
  position: relative;
}
.group-left{
  width: 175px;
  border-right: 1px solid #DDDDDD;
}
.group-right{
  margin-left: 16px;
  width: 165px;
}

/deep/ .ant-collapse > .ant-collapse-item{
  border: none;
  margin-top: 5px;
}
/deep/ .ant-collapse{
  border: none;
  background-color:#fff;
  font-size: 12px;
}
/deep/ .ant-collapse-content{
  border: none;
}
/deep/ .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.group-user-list{
  margin-top: 5px;
  height: 360px;
  overflow: hidden;
  overflow-y: auto;
}
.group-user-list::-webkit-scrollbar {
  display: none;
}
/deep/ .ant-collapse-content-box{
  padding: 0px;
}
.useUser-body{
  width: 165px;
  height: 360px;
  display: flex;
}
.useUser{
  margin-top: 8px;
  position: relative;
  margin-right: 8px;
}
.useUser-name{
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
}
.useUser-icon{
  width: 20px;
  height: 20px;
  position:absolute;
  top: -6px;
  right: -5px;
  cursor: pointer;
}
/deep/ .ant-checkbox-wrapper{
  font-size: 12px;
}


.login-button-queren {
  width: 60px;
  height: 29px;
  border-radius: 4px;
  background: linear-gradient(90deg, #FE5D42 0%, #ED2616 100%);
  border: none;
  color: #fff;
  margin-top: 21px;
  line-height: 20px;
  cursor: pointer;
}
.login-button-quxiao {
  width: 60px;
  line-height: 20px;
  height: 29px;
  border-radius: 4px;
  color: #fff;
  background: linear-gradient(90deg, #ccc 0%, #ccc 100%);
  border: none;
  margin-top: 21px;
  cursor: pointer;
}

.seachgroupuser-input {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  background: #EEEEEE;
  width: 155px;
  height: 29px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 29px;
  outline: none;
  color: #999;
  font-size: 12px;
}
.seachgroupuser-input:focus {
  outline: none;
  border: 1px solid #D8D8D8;
}

.seachgroupuser-input::placeholder {
  color: #999999;
  font-size: 12px;
}
.group-body{
  display: flex;
  justify-content: space-between;   
}
.ftitle{
    font-size: 12px;
}

.groupclose{
  width: 18px;
  height: 18px;
}

.seach-button-group{
  background-image: url('~@/assets/seach.png');
  background-size: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 130px;
  cursor: pointer;
}
.groupnote{
    height: 30px;
    width: 120px;
}
.save-groupnote{
  margin-top: 6px;
  margin-left: 10px;
}
</style>
